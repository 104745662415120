<script setup lang="ts">
import { ref, toRefs } from 'vue';
import SuperAppLayout from "@/Layouts/SuperAppLayout.vue";
import Card from "../Components/Card.vue";
import { formatDate } from "../composables/formatDate.js";
import { Link } from "@inertiajs/vue3";


const { permissions } = defineProps<{ permissions: object }>()
const date = s => formatDate(s);

const { canAccessAssessments, canReview, canManageTeams, canViewTeams } = toRefs(permissions);


</script>

<template>
    <SuperAppLayout :title="title">
        <template #header>
        </template>
        <div class="p-8 md:px-16 lg:ml-16 sm:max-w-sm sm:mx-auto">
            <Link v-if="canAccessAssessments" :href="route('team-members.index')">
            <Card class="w-full !rounded-2xl bg-white my-9 hover:bg-blue-100 hover:shadow-lg">
                <img src="/user.png" class="h-12 mx-auto" />
                <h2 class="text-lg font-medium my-4">{{ 'Persoonlijk dashboard' }}</h2>
            </Card>
            </Link>
            <Link v-if="canViewTeams" :href="route('dashboard')">
            <Card class="w-full !rounded-xl bg-white my-9 hover:bg-blue-100 hover:shadow-lg">
                <img src="/plot.png" class="h-12 mx-auto" />
                <h2 class="text-lg font-medium my-4">{{ 'Talenten module' }}</h2>
            </Card>
            </Link>
            <Link v-if="canManageTeams" :href="route('teams')">
            <Card class="w-full !rounded-xl bg-white my-9 hover:bg-blue-100 hover:shadow-lg">
                <img src="/team.png" class="h-12 mx-auto" />
                <h2 class="text-lg font-medium my-4">{{ 'Teamsbeheer' }}</h2>
            </Card>
            </Link>
            <Link v-if="canReview" :href="route('reviews.index')">
            <Card class="w-full !rounded-xl bg-white my-9 hover:bg-blue-100 hover:shadow-lg">
                <img src="/review.png" class="h-12 mx-auto" />
                <h2 class="text-lg font-medium my-4">{{ 'Beoordelen' }}</h2>
            </Card>
            </Link>
            <div v-if="!canAccessAssessments && !canReview && !canManageTeams" class="text-center text-gray-500">
                <p>Je hebt geen toegang tot assessments.</p>
                <!-- stuur bericht naar manager -->
            </div>

        </div>
    </SuperAppLayout>
</template>
