<script setup lang="ts">
import { computed, defineProps } from 'vue';
import teamImg from '../../../../img/team3.svg';

const props = defineProps({
  team: Object,
  editable: Boolean,
});

const tileRoute = computed(() => {
  return props.editable ? route('teams.edit', props.team.id) : route('teams.dashboard', props.team.id);
});
</script>
<template>
  <a :href="tileRoute">
    <div class="rounded-lg shadow bg-white w-64 content-center hover:shadow-lg hover:bg-blue-100">
      <div class="pt-6 pb-3">
        <img class="h-24 min-w-12 mx-auto gap-3 opacity-80 hover:opacity-100" :src="teamImg" />
      </div>
      <a v-if="editable" :href="route('teams.dashboard', team.id)"
        class="block text-center text-blue-800 underline hover:text-blue-950 cursor-pointer">Bekijk groepsoverzicht</a>
      <h3 v-if="team.company_name !== ''" class="text-xs bg-blue-150 pt-6 px-2 text-center">{{ team.company_name
        }}</h3>
      <h2 class="text-md font-medium bg-blue-150 pb-6 px-2 text-center rounded-b-lg">{{ team.name }}</h2>
    </div>
  </a>
</template>